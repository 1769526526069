import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.css';

import './index.scss';
import App from "./App";
import * as serviceWorker from "./serviceWorker";

const params = new URLSearchParams(document.location.search);
window.playerId = params.get("playerId");
window.frameId = params.get("iframeId");
window.duration = params.get("duration");

document.addEventListener('DOMContentLoaded', function(event) { 
  window.parent.postMessage({'event': 'loaded', 'frameId': window.frameId}, '*')
});

ReactDOM.render(
  <Router>
    <App />
  </Router>,
  document.getElementById("root")
);
serviceWorker.register({
  onUpdate: registration => {
    console.info(
        'Received a service worker update, unregister old service worker and restart browser',
    );
    registration.unregister().then(() => {
      window.location.reload();
    });
  },
  onSuccess: registration => {
    console.log('New service worker loaded');
  },
});
