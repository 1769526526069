import React from "react";
import { Route, Switch } from "react-router-dom";

import asyncComponent from "./components/AsyncComponent";
const Calling = asyncComponent(() => import("./containers/v1/calling"));
const Facilities = asyncComponent(() => import("./containers/v1/facilities"));
const News = asyncComponent(() => import("./containers/v1/news"));
const Pumpconfirmation1 = asyncComponent(() =>
  import("./containers/v1/pumpconfirmation1")
);
const Pumpconfirmation2 = asyncComponent(() =>
  import("./containers/v1/pumpconfirmation2")
);
const Pumpconfirmation3 = asyncComponent(() =>
  import("./containers/v1/pumpconfirmation3")
);
const Transaction = asyncComponent(() => import("./containers/v1/transaction"));
const Weather = asyncComponent(() => import("./containers/v1/weather"));

const Test = asyncComponent(() => import("./containers/test"));

const V1App = asyncComponent(() => import("./containers/v1/App"));
const V2App = asyncComponent(() => import("./containers/v2/App"));

export default ({ childProps }) => {
  return (
    <Switch>
      <Route path="/calling" component={Calling} />
      <Route path="/facilities" component={Facilities} />
      <Route path="/news" component={News} />
      <Route path="/pumpconfirmation1" component={Pumpconfirmation1} />
      <Route path="/pumpconfirmation2" component={Pumpconfirmation2} />
      <Route path="/pumpconfirmation3" component={Pumpconfirmation3} />
      <Route path="/transaction" component={Transaction} />
      <Route path="/test" component={Test} />
      <Route path="/weather" component={Weather} />
      <Route path="/v1" component={V1App} />
      <Route path="/v2" component={V2App} />
    </Switch>
  );
};
