import React, { Component } from "react";
import Routes from "./Routes";
import fs from "bro-fs";

fs.init({ type: window.TEMPORARY, bytes: 5 * 1024 * 1024 })
// .then(() => fs.clear())

class App extends Component {
  render() {
    return <Routes />;
  }
}

export default App;
